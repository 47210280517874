import React, { ComponentProps, ForwardedRef, forwardRef, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";
import { TeliaButton } from "@telia/b2x-telia-button";
import { HeadingTagType, TeliaHeading } from "@telia/b2x-telia-heading";
import { IconName, TeliaIcon } from "@telia/b2x-telia-icon";

import styles from "./b2x-telia-notification.module.scss";
import {
  NOTIFICATION_STATUS,
  NotificationLive,
  NotificationRole,
  NotificationStatus,
} from "./types";

const rootClassName = "b2x-telia-notification";

const getStatusIcon = (status: NotificationStatus): IconName => {
  switch (status) {
    case NOTIFICATION_STATUS.INFORMATION:
      return "info-filled";
    case NOTIFICATION_STATUS.SUCCESS:
      return "check-circle-filled";
    case NOTIFICATION_STATUS.WARNING:
      return "alert-filled";
    case NOTIFICATION_STATUS.ERROR:
      return "error-filled";
  }
};

const createClassName = (classNames: (string | boolean | undefined)[]) =>
  classNames
    .filter((className): className is string => !!className)
    .map((className) => styles[className])
    .join(" ");

type Props = ComponentProps<"article"> & {
  "data-testid"?: string;
  ariaLive?: NotificationLive;
  closeButtonText?: string;
  headingTag?: HeadingTagType;
  onClose?: () => void;
  status?: NotificationStatus;
  heading?: string;
  role?: NotificationRole;
};

const TeliaNotificationComponent = <T extends HTMLElement>(
  {
    ["data-testid"]: dataTestId,
    ariaLive,
    closeButtonText,
    children,
    headingTag = "h4",
    onClose,
    role,
    status = "information",
    heading,
    ...articleProps
  }: Props,
  ref: ForwardedRef<T>
) => {
  useEffect(() => {
    obsoleteWarning(rootClassName, "components-notification--docs");
  }, []);

  const className = createClassName([rootClassName, `${rootClassName}--${status}`]);

  return (
    <article
      aria-describedby={role ? "content" : undefined}
      aria-labelledby={role ? "heading" : undefined}
      aria-live={ariaLive}
      className={className}
      data-testid={dataTestId}
      ref={ref}
      role={role}
      {...articleProps}
    >
      <TeliaIcon
        className={createClassName([`${rootClassName}__icon`])}
        name={getStatusIcon(status)}
        size="md"
      />
      <div className={createClassName([`${rootClassName}__content-container`])}>
        {onClose && (
          <TeliaButton
            variant="text"
            className={styles[`${rootClassName}__button`]}
            ally-label={`${closeButtonText}-${status}`}
            onClick={onClose}
          >
            {closeButtonText}
            <TeliaIcon name="close" size="md" />
          </TeliaButton>
        )}
        {!!heading && (
          <TeliaHeading
            data-testid={dataTestId ? `${dataTestId}-heading` : undefined}
            variant="subsection-100"
            tag={headingTag}
            className={createClassName([
              `${rootClassName}__teliaHeading`,
              !children && heading && `${rootClassName}__teliaHeading--no-children`,
            ])}
          >
            {heading}
          </TeliaHeading>
        )}
        {children}
      </div>
    </article>
  );
};

export const TeliaNotification = forwardRef(TeliaNotificationComponent);
TeliaNotification.displayName = "TeliaNotification";
