export const NOTIFICATION_STATUS = {
  SUCCESS: "success",
  INFORMATION: "information",
  WARNING: "warning",
  ERROR: "error",
} as const;

export const notificationStatuses = Object.values(NOTIFICATION_STATUS);
export type NotificationStatus = typeof NOTIFICATION_STATUS[keyof typeof NOTIFICATION_STATUS];

export const NOTIFICATION_LIVE = {
  POLITE: "polite",
  ASSERTIVE: "assertive",
} as const;

export const notificationLiveValues = Object.values(NOTIFICATION_LIVE);
export type NotificationLive = typeof NOTIFICATION_LIVE[keyof typeof NOTIFICATION_LIVE];

export const NOTIFICATION_ROLE = {
  ALERT: "alert",
  DIALOG: "dialog",
  ALERTDIALOG: "alertdialog",
} as const;

export const notificationRoles = Object.values(NOTIFICATION_ROLE);
export type NotificationRole = typeof NOTIFICATION_ROLE[keyof typeof NOTIFICATION_ROLE];
